<template>
    <div class="action-unknown">
        <MessageText
            :message="{
                content: $translate('MTYPE_UNKNOWN'),
            }"
        />
    </div>
</template>

<script>
import MessageText from '../MessageText'

export default {
    name: 'ActionUnknown',
    components: { MessageText },
}
</script>
